import * as React from 'react'
import {Button, Modal, Tab} from '@quotefactory/ui'
import {Layout} from 'pages'
import {JSONTree} from 'react-json-tree'
import useRunReport from 'hooks/use-run-report'
import api from '../services/api'
import ProvidersTable from '../components/ProvidersTable'
import DataTable from '../components/reports/DataTable'

export default function ProviderList() {
  const [run, {isRunning, data, clearData}] = useRunReport()

  const sortedData = React.useMemo(() => {
    if (!data) {
      return []
    }
    return data.sort((a, b) => {
      if (a.broker < b.broker) return -1
      if (a.broker > b.broker) return 1
      return 0
    })
  }, [data])

  const runBrokersByVendor = async () => {
    if (provider) {
      await run({
        reportName: 'Vendors',
        runAsync: false,
        format: 'json',
        params: {
          providerId: provider.id,
        },
      })
    }
  }

  const [provider, setProvider] = React.useState()
  const handleRowClick = React.useCallback((id) => {
    setProvider(id)
  }, [])
  const handleClose = React.useCallback(() => {
    setProvider(null)
  }, [])

  const [isLoading, setLoading] = React.useState(false)
  const refreshProvider = React.useCallback(async () => {
    if (provider) {
      setLoading(true)
      await api.refreshProviders({usdots: [provider.usdot]})
      setLoading(false)
    }
  }, [provider])

  return (
    <>
      <Layout>
        <ProvidersTable onRowClick={handleRowClick} />
        <Modal isOpen={!!provider} onClose={handleClose}>
          <Modal.Content className="pl-2 ml:w-[1000px] overflow-hidden">
            <div className="flex flex-col justify-between">
              <div className="px-8 py-4 border-b border-gray-300">
                <div className="flex justify-between py-2 items-center">
                  <h1 className="py-2">
                    {provider?.friendlyName || provider?.name}
                  </h1>
                  <div>
                    <Button
                      type="button"
                      onClick={refreshProvider}
                      className="btn"
                      loading={isLoading}>
                      SAFER Refetch
                    </Button>
                  </div>
                </div>
                <div className="flex justify-between py-2">
                  <h2>{provider?.usdot}</h2>
                  <h2>
                    {provider?.mcNumbers
                      .map((mcNumber) => `MC-${mcNumber}`)
                      .join(', ')}
                  </h2>
                </div>
              </div>

              <Tab.Group>
                <Tab.List className="w-full flex items-center gap-2 py-4 px-6">
                  <Tab as={React.Fragment}>
                    {({selected}) => (
                      <button
                        type="button"
                        onClick={clearData}
                        title={`View "raw" JSON properties of ${
                          provider?.friendlyName || 'this provider'
                        }`}
                        className={`px-2 pb-1 border-b-[3px] ${
                          selected
                            ? 'text-black-500 border-blue-500'
                            : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                        }`}>
                        JSON
                      </button>
                    )}
                  </Tab>
                  <Tab as={React.Fragment}>
                    {({selected}) => (
                      <button
                        type="button"
                        onClick={runBrokersByVendor}
                        title={`View what brokers are configured to use ${
                          provider?.friendlyName || 'this provider'
                        }`}
                        className={`px-2 pb-1 border-b-[3px] ${
                          selected
                            ? 'text-black-500 border-blue-500'
                            : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                        }`}>
                        Brokers
                      </button>
                    )}
                  </Tab>
                </Tab.List>

                <Tab.Panels className="flex-1 flex flex-col max-h-[70vh] overflow-y-scroll">
                  <Tab.Panel as={React.Fragment}>
                    <div className="py-4 px-3 pr-4">
                      <div>
                        <JSONTree
                          data={provider}
                          hideRoot
                          shouldExpandNode={(keyPath) => {
                            if (
                              keyPath.length === 1 &&
                              keyPath.includes('orcusProvider')
                            ) {
                              return true
                            }
                          }}
                          theme={{
                            scheme: 'monokai',
                            author: 'wimer hazenberg (http://www.monokai.nl)',
                            base00: '#272822',
                            base01: '#383830',
                            base02: '#49483e',
                            base03: '#75715e',
                            base04: '#a59f85',
                            base05: '#f8f8f2',
                            base06: '#f5f4f1',
                            base07: '#f9f8f5',
                            base08: '#f92672',
                            base09: '#fd971f',
                            base0A: '#f4bf75',
                            base0B: '#a6e22e',
                            base0C: '#a1efe4',
                            base0D: '#66d9ef',
                            base0E: '#ae81ff',
                            base0F: '#cc6633',
                          }}
                        />
                      </div>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel as={React.Fragment}>
                    <div className="py-4 px-3 pr-4 min-h-[70vh]">
                      <DataTable
                        data={sortedData}
                        filename={`${provider?.friendlyName || 'vendor'}-${
                          provider?.usdot
                        }-brokers.csv`}
                        isLoading={isRunning}
                      />
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </Modal.Content>
        </Modal>
      </Layout>
    </>
  )
}
