import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from '@reach/router'

import {getAuthenticatedUser} from '../reducers/user'
import auth0 from '../services/auth0'
import ErrorPage from './ErrorPage'

export default function AuthRoute({children}) {
  const [error, setError] = useState()
  const [isReady, setIsReady] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const metadata = {authenticated: false}

      try {
        // Redirect user to login if not authenticated
        if (!(await auth0.isAuthenticated())) {
          await auth0.loginWithRedirect({
            appState: {redirectToAfterLogin: window.location.pathname},
          })
          return
        }

        metadata.authenticated = true

        // Get user and ensure they are an admin
        {
          const result = await dispatch(getAuthenticatedUser())

          if (result.error) {
            throw new Error(result.payload.message)
          }

          metadata.user = result.payload

          if (result.payload?.userRole !== 'admin') {
            throw new Error('Not found')
          }
        }

        setIsReady(true)
      } catch (err) {
        setError(
          Object.assign(err ?? new Error('Unexpected error'), {metadata}),
        )
      }
    })()
  }, [])

  if (error) {
    return (
      <ErrorPage title={error.message} error={error}>
        <div className="flex">
          <Link to="/logout" className="btn outlined white text-17 wide mx-4">
            Logout
          </Link>
        </div>
      </ErrorPage>
    )
  }

  if (isReady) {
    return children
  }

  return null
}
