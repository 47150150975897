import fetch from 'isomorphic-fetch'
import config from 'config'
import auth0 from './auth0'

///////////////////////////////////////////////////////////////////////////////
// REST  base
///////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////
// For all API calls, try to use the full account auth token if it
// exists. If not, grab the user auth token. This should only be
// because we're making an API call required to create the account
// token in the first place.
//////////////////////////////////////////////////////////////////////

/**
 * Creates a new URLSearchParams from the provided object
 * If a value from the object is an array, it will append
 *
 * @template {{[key: string]: string[] | number[] | boolean[] | string | number | boolean | undefined}} A
 *
 * @param {A} paramsObj
 *
 * @returns A new URLSearchParams object with the changes applied
 */
export const objectToSearchParams = (paramsObj) => {
  const searchParams = new URLSearchParams()

  for (const [key, value] of Object.entries(paramsObj)) {
    if (value !== undefined && value !== null && value !== '') {
      if (Array.isArray(value)) {
        for (const val of value) {
          searchParams.append(key, val.toString())
        }
      } else {
        searchParams.append(key, value.toString())
      }
    }
  }

  return searchParams
}

const buildHeaders = (authToken, accountId) => {
  // Use a passed-in accountId or the Admin's accountId
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`
  }
  if (accountId) {
    headers['X-Account-Id'] = accountId
  }
  return headers
}

const api = async (method, route, payload, accountId, returnRawRes = false) => {
  let authToken
  try {
    authToken = await auth0.getTokenSilently()
  } catch (err) {
    // User isn't logged in
  }

  try {
    const opts = {
      method,
      headers: buildHeaders(authToken, accountId),
    }

    if (payload) opts.body = JSON.stringify(payload)

    const url = `${config.QF_API_URL}/${route}`
    const response = await fetch(url, opts)

    if (response.status > 201) {
      let data

      try {
        data = await response.json()
      } catch (err) {
        // The API currently will return "Internal Server Error"
        // which is not JSON encoded and causes this to be necessary.
        const error = new Error(`API returned status ${response.status}`)
        error.statusCode = response.status
        throw error
      }

      const error = new Error(data.message)
      error.statusCode = response.status
      error.fieldErrors = data.errors
      error.apiStack = data.stack

      throw error
    }

    if (returnRawRes || method === 'head') {
      return response
    }

    const data = await response.json()
    return data
  } catch (err) {
    console.error(err)

    throw err
  }
}

///////////////////////////////////////////////////////////////////////////////
// REST  base
///////////////////////////////////////////////////////////////////////////////

const fetchFeatures = async (query) => {
  const result = await api('get', 'features')
  return result
}

const fetchProviders = async ({search, limit, skip, orcus} = {}) => {
  const qs = new URLSearchParams()

  if (search) qs.append('search', search)
  if (limit) qs.append('limit', limit)
  if (skip) qs.append('skip', skip)
  if (orcus) qs.append('orcus', orcus)

  const result = await api('get', `providers?${qs.toString()}`)

  return result
}

const fetchAuthenticatedUser = async () => {
  const result = await api('get', 'me')
  return result
}

const updateUser = async (data) => {
  const result = await api('put', 'users', data)
  return result
}

const fetchAccounts = async (light) => {
  const url = light ? 'accounts?light' : 'accounts'
  const result = await api('get', url, null)
  return result
}

const fetchAccount = async (accountId) => {
  const result = await api('get', `accounts/${accountId}`, null)
  return result
}

const updateAccount = async (accountId, data) => {
  const result = await api('put', 'accounts', data, accountId)
  return result
}

const fetchAccountUsers = async (brokerId) => {
  const result = await api('get', `accounts/users`, null, brokerId)
  return result
}

const updateAccountIntegration = async (data) => {
  const result = await api(
    'put',
    `accounts/${data.accountId}/integrations/${data.id}`,
    data,
  )

  return result
}

const fetchAccountProviderIntegrations = async (brokerId) => {
  const result = await api(
    'get',
    `provider-integrations/account-summary`,
    null,
    brokerId,
  )
  return result
}

const updateProviderIntegration = async ({accountId, ...request}) => {
  const result = await api('put', 'provider-integrations', request, accountId)
  return result
}

const fetchShipment = async (shipmentId) => {
  const result = await api('get', `shipments/${shipmentId}`)
  return result
}

const fetchShipmentBOL = async (shipmentId) => {
  const result = await api('get', `shipments/${shipmentId}/bol`)
  return result
}

const fetchDistinctShipmentValues = async (prop) => {
  const result = await api('get', `shipments/distinct/${prop}`)
  return result
}

const fetchDistinctProviders = async () => {
  const result = await api('get', `shipments/distinct/provider`)
  return result
}

const listShipments = async (options) => {
  const qs = new URLSearchParams()
  const {brokerId, skip, sortBy, limit, filters, count} = options
  if (skip) qs.set('skip', skip)
  if (limit) qs.set('limit', limit)
  if (sortBy) qs.set('sortBy', sortBy)
  if (count || count === false) qs.set('count', count)
  for (const [key, val] of Object.entries(filters)) {
    if (val) {
      qs.set(key, val)
    }
  }
  const result = await api('get', `shipments?${qs.toString()}`, null, brokerId)
  return result
}

const countShipments = async (options) => {
  const qs = new URLSearchParams()
  const {brokerId, sortBy, filters} = options
  if (sortBy) qs.set('sortBy', sortBy)
  for (const [key, val] of Object.entries(filters)) {
    if (val) {
      qs.set(key, val)
    }
  }
  const result = await api('head', `shipments?${qs.toString()}`, null, brokerId)
  return {
    total: result.headers.get('x-total-count')
      ? parseInt(result.headers.get('x-total-count'), 10)
      : 0,
  }
}

const fetchPlace = async (placeId) => {
  const result = await api('get', `places/${placeId}`)
  return result
}

const updateShipment = async (accountId, shipmentId, shipment) => {
  const result = await api(
    'put',
    `shipments/${shipmentId}`,
    shipment,
    accountId,
  )
  return result
}

const cloneXNShipment = async (shipmentId) => {
  const result = await api('post', `shipments/${shipmentId}/xnclone`)
  return result
}

const runReport = async ({reportName, runAsync, params} = {}) => {
  const qs = objectToSearchParams(params)

  const result = await api(
    'get',
    `reports/${reportName}/${runAsync ? 'async/' : ''}?${qs.toString()}`,
    null,
    null,
    true,
  )

  return result
}

const refreshProviders = async ({usdots = []} = {}) => {
  const result = await api('post', `providers/refresh`, {usdots})

  return result
}

const importShipment = async ({
  accountId,
  dryRun,
  upsert,
  mapBox,
  timezone,
  row,
}) => {
  const result = await api(
    'post',
    'data-import/shipment',
    {
      dryRun,
      upsert,
      mapBox,
      timezone,
      row,
    },
    accountId,
  )
  return result
}

const importCustomer = async ({accountId, dryRun, upsert, timezone, row}) => {
  const result = await api(
    'post',
    'data-import/customer',
    {
      dryRun,
      upsert,
      timezone,
      row,
    },
    accountId,
  )
  return result
}

const importVendor = async ({accountId, dryRun, upsert, timezone, row}) => {
  const result = await api(
    'post',
    'data-import/vendor',
    {
      dryRun,
      upsert,
      timezone,
      row,
    },
    accountId,
  )
  return result
}

const setBolSequence = async (accountId, nextNumber) => {
  const result = await api('post', 'accounts/set-bol', {nextNumber}, accountId)
  return result
}

const getCloseIoDetails = async (accountId) => {
  const result = await api('get', `accounts/${accountId}/closeio`)
  return result
}

export default {
  fetchFeatures,
  fetchProviders,
  fetchAuthenticatedUser,
  updateUser,
  fetchAccounts,
  fetchAccount,
  updateAccount,
  fetchAccountUsers,
  fetchAccountProviderIntegrations,
  updateAccountIntegration,
  updateProviderIntegration,
  fetchShipment,
  fetchShipmentBOL,
  updateShipment,
  fetchDistinctShipmentValues,
  fetchDistinctProviders,
  countShipments,
  listShipments,
  fetchPlace,
  cloneXNShipment,
  runReport,
  refreshProviders,
  importVendor,
  importCustomer,
  importShipment,
  setBolSequence,
  getCloseIoDetails,
}
