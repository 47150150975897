import * as React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {JSONTree} from 'react-json-tree'
import {fetchShipmentById, selectShipmentById} from 'reducers/shipment'

export default function ShipmentJSON({shipmentId}) {
  const dispatch = useDispatch()

  const {shipment} =
    useSelector((state) => selectShipmentById(state, shipmentId)) || {}

  React.useEffect(() => {
    dispatch(fetchShipmentById(shipmentId))
  }, [])

  if (!shipment) {
    return null
  }

  return (
    <div className="p-8">
      <h2>
        {shipment.broker.name}: {shipment.bolNumber}
      </h2>
      <JSONTree
        data={shipment}
        hideRoot
        theme={{
          scheme: 'monokai',
          author: 'wimer hazenberg (http://www.monokai.nl)',
          base00: '#272822',
          base01: '#383830',
          base02: '#49483e',
          base03: '#75715e',
          base04: '#a59f85',
          base05: '#f8f8f2',
          base06: '#f5f4f1',
          base07: '#f9f8f5',
          base08: '#f92672',
          base09: '#fd971f',
          base0A: '#f4bf75',
          base0B: '#a6e22e',
          base0C: '#a1efe4',
          base0D: '#66d9ef',
          base0E: '#ae81ff',
          base0F: '#cc6633',
        }}
      />
    </div>
  )
}
