import React from 'react'
import {useSelector} from 'react-redux'
import {Layout} from 'pages'

export default function Dashboard() {
  const user = useSelector((state) => state.user)

  return (
    <Layout>
      <div className="flex items-center py-2 px-4 xl:px-4 justify-between my-6">
        <h2>Admin</h2>
        <div className="text-14 font-semibold flex items-center space-x-2">
          <span>{user.fullName}</span>
        </div>
      </div>

      <div className="border-2 border-gray-500 border-dashed rounded-3xl grid place-items-center text-24 font-bold text-black-200 min-h-[450px]">
        <span className="flex items-center gap-3">
          New graphs coming soon... <span className="text-52">📊</span>
        </span>
      </div>
    </Layout>
  )
}
