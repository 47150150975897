import React from 'react'
import {UserAvatar} from 'components'

export default function AccountUser({member}) {
  if (!member) return null
  const {fullName, isOwner} = member

  return (
    <div className="flex items-center gap-3">
      <UserAvatar user={member} size={3} />
      <div>
        <div className="text-15 font-semibold pb-0.5">{fullName}</div>
        <div
          className={`inline-block text-white font-bold tracking-wide px-1 py-px text-11 rounded mr-1 ${
            isOwner ? 'bg-green-500' : 'bg-black-100'
          }`}>
          {isOwner ? 'Owner' : 'Member'}
        </div>
      </div>
    </div>
  )
}
