// RTK builders have specific shapes of args that we cannot affect. Ignore this for now.
/* eslint-disable default-param-last */

import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import {objectToSearchParams} from '../helpers/search-params'
import {difference} from '../helpers/array'
import auth0 from './auth0'

/**
 * @typedef {typeof tagTypes[number]} QueryTagNames
 */

const tagTypes = /** @type {const} */ ([])

/**
 * @type {{[key in QueryTagNames]: (input: any) => {id: string, type: key}}}
 */
export const tag = tagTypes.reduce(
  (acc, type) => ({
    ...acc,
    [type]: (key) => ({type, id: key?.toString() ?? ''}),
  }),
  /** @type {any}*/ ({}),
)

const qfAPI = createApi({
  reducerPath: 'qfAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: config.QF_API_URL,
    prepareHeaders: async (headers) => {
      let authToken
      try {
        authToken = await auth0.getTokenSilently()
      } catch (err) {
        // User isn't logged in
      }

      if (authToken) {
        headers.set('Authorization', `Bearer ${authToken}`)
      }

      if (window.location.href) {
        headers['X-App-URL'] = window.location.href
      }

      return headers
    },
  }),

  tagTypes,
  endpoints: (builder) => ({
    ////////////////////////////////////
    // Areas
    ////////////////////////////////////

    getAreas: builder.query({
      /**
       *
       * @param {{s: string}} params
       */
      query: (params) => {
        return {
          url: 'places/areas',
          params: objectToSearchParams(params),
        }
      },
    }),

    ////////////////////////////////////
    // Providers
    ////////////////////////////////////

    getProvider: builder.query({
      /**
       *
       * @param {{providerId: string}} params
       */
      query: (params) => {
        return {
          url: `providers/${params.providerId}`,
        }
      },
    }),

    ////////////////////////////////////
    // MFS (XN) linked customer
    ////////////////////////////////////

    getLinkedCustomer: builder.query({
      query: ({id}) => {
        return {
          url: `accounts/linked-customer`,
          method: 'get',
          headers: {'x-account-id': id},
        }
      },
    }),

    ////////////////////////////////////
    // Search provider by identifier
    ////////////////////////////////////

    getProviderByIdentifier: builder.query({
      query: ({identifier}) => {
        return {
          url: `providers`,
          method: 'get',
          params: {identifier},
        }
      },
    }),

    ////////////////////////////////////
    // Pathfinder setup
    ////////////////////////////////////

    setupPathfinder: builder.mutation({
      query: (payload) => {
        return {
          url: 'integrations/pathfinder/setup',
          method: 'POST',
          body: payload,
        }
      },
    }),

    ////////////////////////////////////
    // Credit usage
    ////////////////////////////////////

    getBrokerXnCreditUsage:
      /** @type {RetunType<typeof builder.query<QF.CreditUsageResult, GetBrokerXnCreditUsageInput>>} */
      (
        builder.query({
          query({id}) {
            return {
              url: `accounts/xn-credit-usage`,
              method: 'get',
              headers: {'x-account-id': id},
            }
          },
          keepUnusedDataFor: 0,
          transformResponse: (/** @type {QF.CreditUsageResult} */ data) => {
            return {
              ...data,

              // Calculate the on schedule shipments and balance
              onScheduleInvoiceShipmentIds: difference(
                data.openInvoiceShipmentIds,
                data.overdueInvoiceShipmentIds,
              ),
              onScheduleInvoiceShipmentBalance:
                data.openInvoiceShipmentBalance -
                data.overdueInvoiceShipmentBalance,
            }
          },
        })
      ),

    ////////////////////////////////////
    // Close IO details for a broker
    ////////////////////////////////////

    getCloseIO: builder.query({
      query: (id) => {
        return {
          url: `accounts/closeio`,
          method: 'get',
          headers: {'x-account-id': id},
        }
      },
    }),

    ////////////////////////////////////
    // Broker pace data
    ////////////////////////////////////

    getPace: builder.query({
      query: () => {
        return {
          url: `accounts/pace`,
        }
      },
    }),

    listMetabaseDashboards: builder.query({
      query() {
        return {
          url: '/reports/dashboards/metabase',
          method: 'get',
        }
      },
    }),

    getMetabaseDashboard: builder.query({
      query({dashboardId}) {
        return {
          url: `/reports/dashboards/metabase/${dashboardId}`,
          method: 'get',
        }
      },
    }),
  }),
})

export default qfAPI

export const {
  useGetAreasQuery,
  useGetProviderQuery,
  useGetBrokerXnCreditUsageQuery,
  useGetLinkedCustomerQuery,
  useSetupPathfinderMutation,
  useGetProviderByIdentifierQuery,
  useGetCloseIOQuery,
  useGetPaceQuery,
  useListMetabaseDashboardsQuery,
  useGetMetabaseDashboardQuery,
} = qfAPI
