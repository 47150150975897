import * as React from 'react'
import Papa from 'papaparse'
import {useDebounce} from 'use-debounce'
import {Link} from '@reach/router'
import {SVG, Button, Toggle} from '@quotefactory/ui'
import {useSelector, useDispatch} from 'react-redux'
import {fetchAccount, selectAccountById} from 'reducers/account'
import {Layout} from 'pages'
import useFileDragDrop from '../../hooks/use-file-drag-drop'
import useImport from './use-import'
import timezonesForImportTranslateToUTC from './helpers'
import ResultsPanel from './ResultsPanel'

export default function AccountImportShipment({accountId}) {
  const dispatch = useDispatch()

  const [options, setOptions] = React.useState({
    dryRun: true,
    upsert: false,
    mapBox: false,
    timezone: timezonesForImportTranslateToUTC[0],
  })

  const [bolNumberFilter, setBolNumberFilter] = React.useState('')
  const [debouncedBolNumberFilter] = useDebounce(bolNumberFilter, 500)

  const [data, setData] = React.useState([])

  const {
    importState,
    isImporting,
    runControls,
    reset,
    progress,
    stats,
    results,
  } = useImport({accountId, options, apiFunctionName: 'importShipment', data})

  const account =
    useSelector((state) => selectAccountById(state, accountId)) || {}

  React.useEffect(() => {
    dispatch(fetchAccount(accountId))
  }, [])

  const {file, FileSelector} = useFileDragDrop()

  const getDataFromFile = () => {
    const bolNumbers = bolNumberFilter.split(',').map((i) => i.trim())
    Papa.parse(file, {
      header: true,
      complete: (parsedResults) => {
        setData(
          parsedResults.data.filter(
            (row) => !bolNumberFilter || bolNumbers.includes(row.bolNumber),
          ),
        )
      },
    })
  }

  React.useEffect(() => {
    if (file) {
      getDataFromFile()
      reset()
    }
  }, [file, debouncedBolNumberFilter])

  const toggleOption = (evt, checked) => {
    const option = evt.target.id
    setOptions((prev) => ({...prev, [option]: checked}))
  }

  const onOptionChange = (evt) => {
    const timezone = evt.target.value
    setOptions((prev) => ({...prev, timezone}))
  }

  const onResultClick = (resultProps) => {
    if (resultProps?.id) {
      const rows = data.filter((r) => r.bolNumber === resultProps.id)
      if (rows.length > 1) {
        console.log(rows)
      } else if (rows.length === 1) {
        console.log(rows[0])
      } else {
        console.log('Data not found matching', resultProps)
      }
    }
  }

  return (
    <Layout className="max-w-[1600px]">
      <nav
        className="flex items-center justify-between pt-5 pb-8"
        aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4 m-0">
          <li>
            <div>
              <Link
                to="/accounts"
                className="flex items-center space-x-1 text-gray-800 hover:text-black-500">
                <SVG
                  name="customer"
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <div>Accounts</div>
              </Link>
            </div>
          </li>
          <li>
            <div className="flex items-center space-x-4">
              <SVG
                name="slash"
                className="flex-shrink-0 h-5 w-5 text-gray-600"
              />
              <div className="ml-4 text-sm font-medium text-black-500">
                {account.name}
              </div>
            </div>
          </li>
        </ol>
      </nav>

      <div className="flex-1 flex flex-col  rounded-lg mb-8 bg-gray-300">
        <div className="flex gap-8 bg-white rounded-lg p-8">
          <div className="min-w-[320px]">
            <img
              src={account.logoUrl}
              alt={account.name}
              className="max-w-[200px] max-h-[60px] bg-white text-black-100"
            />

            <div className="flex flex-col divide-y divide-gray-300 pt-6">
              <div className="flex items-center justify-between py-3">
                <div>Dry run</div>
                <Toggle
                  id="dryRun"
                  name="dryRun"
                  checked={options.dryRun}
                  onChange={toggleOption}
                  disabled={isImporting}
                />
              </div>
              <div className="flex items-center justify-between py-3">
                <div>Upsert</div>
                <Toggle
                  id="upsert"
                  name="upsert"
                  checked={options.upsert}
                  onChange={toggleOption}
                  disabled={isImporting}
                />
              </div>
              <div className="flex items-center justify-between py-3">
                <div>Use MapBox</div>
                <Toggle
                  id="mapBox"
                  name="mapBox"
                  checked={options.mapBox}
                  onChange={toggleOption}
                  disabled={isImporting}
                />
              </div>
              <div className="flex items-center justify-between py-4">
                <div>Timezone</div>
                <select
                  name="timezone"
                  value={options.timezone}
                  onChange={onOptionChange}
                  disabled={isImporting}>
                  {timezonesForImportTranslateToUTC.map((tz) => (
                    <option key={tz} value={tz}>
                      {tz}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center justify-between py-4">
                <div className="mr-4">BOL# filter</div>
                <input
                  name="bolNumberFilter"
                  value={bolNumberFilter}
                  onChange={(evt) => setBolNumberFilter(evt.target.value)}
                  disabled={isImporting}
                />
              </div>
              {!isImporting && (
                <Button
                  onClick={runControls.start}
                  className="!text-20 !py-2 !mt-4"
                  disabled={data.length === 0}>
                  Start {options.dryRun ? 'Dry Run' : 'Import'}{' '}
                </Button>
              )}
            </div>
          </div>

          <div className="flex-1 flex flex-col">
            <div className="pb-2 text-xl">Import Shipments</div>
            <FileSelector
              disabled={isImporting}
              dropZoneText={
                file
                  ? `${file.name} (${data.length.toLocaleString()} rows)`
                  : ''
              }
            />
          </div>
        </div>

        {data.length > 0 && (
          <ResultsPanel
            runState={importState}
            results={results}
            stats={stats}
            progress={progress}
            runControls={runControls}
            onResultClick={onResultClick}
          />
        )}
      </div>
    </Layout>
  )
}
