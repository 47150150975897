/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import React, {useEffect, useState, useRef, useMemo} from 'react'
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params'
import {Pagination} from 'components'
import {Filters} from 'components/filters'
import api from 'services/api'
import {Titlebar, Title, SVG} from '@quotefactory/ui'

const PAGE_SIZE = 25
const [PAGE_UP, PAGE_DOWN, HOME, END] = [33, 34, 36, 35]

const QUERY_STORAGE_KEY = 'providers-query'

export default function ProvidersTable({onRowClick}) {
  const scrollContainerRef = useRef()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  // Restore the "remembered" querystring for the current session
  const savedQuery =
    JSON.parse(window.sessionStorage.getItem(QUERY_STORAGE_KEY) || null) || {}

  const [{skip, search}, setQS] = useQueryParams({
    skip: withDefault(NumberParam, 0),
    search: withDefault(StringParam, savedQuery.search || ''),
  })

  const setSkip = (skip) => setQS({skip})

  const [filters, setFilters] = useState({
    search,
  })

  const listQuery = useMemo(
    () => ({
      search: filters.search,
      orcus: 'Y',
      // Strip out filters that have an empty value
      filters: Object.fromEntries(
        Object.entries(filters).filter(([key, val]) => val),
      ),
      skip,
      limit: PAGE_SIZE,
    }),
    [skip, filters],
  )

  const fetchProviders = async () => {
    setLoading(true)
    const res = await api.fetchProviders({
      ...listQuery,
    })
    setData(res.entities)
    setTotalRows(res.total)
    setLoading(false)
  }

  const saveFilters = () => {
    // Sync the querystring with the current state
    const queryData = {
      skip: skip || undefined,
    }
    for (const [key, val] of Object.entries(filters)) {
      queryData[key] = val || undefined
    }
    setQS(queryData)
    // Remember the querystring for this session
    sessionStorage.setItem(QUERY_STORAGE_KEY, JSON.stringify(queryData))
  }

  useEffect(() => {
    fetchProviders()
    scrollContainerRef.current.focus()
    saveFilters()
  }, [listQuery])

  useEffect(() => {
    // When a filter changes, go back to page 1
    setSkip(0)
  }, [filters])

  const [scrollOffset, setScrollOffset] = useState(0)
  const listHeight = 150

  // The magic number "5" is an arbitrary value from trial & error...
  const pageOffset = listHeight * 5
  const maxHeight =
    (scrollContainerRef.current &&
      scrollContainerRef.current.style.height.replace('px', '')) ||
    listHeight

  const minHeight = 0.1

  const keys = {
    [PAGE_UP]: Math.max(minHeight, scrollOffset - pageOffset),
    [PAGE_DOWN]: Math.min(scrollOffset + pageOffset, maxHeight),
    [END]: maxHeight,
    [HOME]: minHeight,
  }

  const keyDownListener = ({keyCode}) => {
    if (keys[keyCode]) {
      setScrollOffset(keys[keyCode])
    }
  }

  let even = true

  return (
    <div className="flex flex-col h-screen">
      <div className="relative">
        <div className="pt-8">
          {/* absolute inset-x-0 z-[40] h-40 md:h-28 bg-gray-200 shadow-[0_0_0_2px] shadow-gray-200 */}
          <Titlebar className="flex-wrap items-center">
            <Title className="flex items-baseline gap-3">
              <span className="py-2">Providers</span>
            </Title>
            <div className="md:ml-auto relative w-full md:w-auto">
              <Filters
                entityType="providers"
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          </Titlebar>
        </div>
      </div>

      <div
        ref={scrollContainerRef}
        className={`flex-1 overflow-auto relative outline-none max-w-[1400px] ${
          totalRows > PAGE_SIZE ? 'mb-0' : ''
        }`}
        onKeyDown={keyDownListener}
        tabIndex="0">
        <table className="relative w-full">
          <thead>
            <tr className="text-black-100 uppercase text-11 leading-normal relative z-20">
              <th className="sticky top-0 bg-gray-200 py-3 pl-2 text-left">
                USDOT
              </th>
              <th className="sticky top-0 bg-gray-200 py-3 text-left">MC</th>
              <th className="sticky top-0 bg-gray-200 py-3 text-left">Name</th>
              <th className="sticky top-0 bg-gray-200 py-3 text-left">
                Physical address
              </th>
              <th className="sticky top-0 bg-gray-200 py-3 text-left">
                Mailing address
              </th>
              <th className="sticky top-0 bg-gray-200 py-3 text-center">
                Is Broker
              </th>
              <th className="sticky top-0 bg-gray-200 py-3 text-center">
                Is Carrier
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {data.map((item) => {
              even = !even
              return (
                <ProviderRow
                  key={item.id}
                  item={item}
                  even={even}
                  onRowClick={onRowClick}
                />
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8} align="center" className="px-6 text-black-100">
                {!loading && data.length < 1 && (
                  <div className="py-20">
                    <SVG name="warningInverted" /> No providers found
                  </div>
                )}
                {loading && <SVG name="loading" className="inline ml-4" />}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div>
        <Pagination
          total={totalRows}
          perPage={data.length > PAGE_SIZE ? data.length : PAGE_SIZE}
          skip={skip}
          onSetSkip={setSkip}
        />
      </div>
    </div>
  )
}

function ProviderRow({item, even, onRowClick}) {
  const {
    name,
    friendlyName,
    usdot,
    mcNumbers,
    isBroker,
    isCarrier,
    mailingAddress,
    physicalAddress,
  } = item

  const rowClass = () => {
    if (even) {
      return 'bg-gray-50'
    }
    return ''
  }

  return (
    <tr
      onClick={() => onRowClick(item)}
      className={`cursor-pointer text-sm text-black-500 hover:bg-white relative ${rowClass()}`}>
      {/* <div className="absolute z-10 inset-y-0 inset-x-[2px] hover:shadow-[0_0_0_2px] hover:shadow-blue-500 rounded-md" /> */}
      <td className="py-2 pl-2">{usdot}</td>
      <td className="py-2">{mcNumbers?.join(', ')}</td>
      <td className="py-2 font-bold">{friendlyName || name}</td>
      <td className="py-2">
        <Address address={physicalAddress} />
      </td>
      <td className="py-2">
        <Address address={mailingAddress} />
      </td>
      <td className="py-2">
        {isBroker && <SVG name="customer" className="w-5 h-5 mx-auto" />}
      </td>
      <td className="py-2">
        {isCarrier && <SVG name="carrier" className="w-5 h-5 mx-auto" />}
      </td>
    </tr>
  )
}

function Address({address}) {
  const {street, city, stateCode, postalCode, countryCode} = address || {}

  return address ? (
    <div>
      {street?.[0] && <div>{street[0]}</div>}
      <div>
        {city}, {stateCode} {postalCode} {countryCode}
      </div>
    </div>
  ) : null
}
