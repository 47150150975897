import React from 'react'
import {Router, Redirect, globalHistory} from '@reach/router'
import {QueryParamProvider} from 'use-query-params'
import {ReachAdapter} from 'use-query-params/adapters/reach'
import {AuthRoute, AuthCallback} from 'components'
import {
  Dashboard,
  Login,
  Logout,
  NotFound,
  Accounts,
  Account,
  Shipments,
  ShipmentJSON,
  Providers,
  Reports,
} from 'pages'
import {
  AccountImportCustomer,
  AccountImportShipment,
  AccountImportVendor,
} from 'pages/data-import'

function Routes() {
  return <Router>
    <QueryParamProvider
      {...{path: '/'}}
      reachHistory={globalHistory}
      adapter={ReachAdapter}>
      <Redirect from="/" to="/dashboard" noThrow />

      <Login path="/login" />
      <Logout path="/logout" />

      <AuthCallback path="/auth" />

      <AuthRoute path="/">
        <Dashboard path="/dashboard" />
        <Accounts path="/accounts" />
        <Account path="/account/:accountId" />
        <AccountImportCustomer path="/account/:accountId/import/customer" />
        <AccountImportShipment path="/account/:accountId/import/shipment" />
        <AccountImportVendor path="/account/:accountId/import/vendor" />
        <Shipments path="/shipments/*" />
        <ShipmentJSON path="/shipment/:shipmentId/json" />
        <Providers path="/providers" />
        <Reports path="/reports" />
        <Reports path="/reports/:report" />

        <NotFound default />
      </AuthRoute>
    </QueryParamProvider>
  </Router>
}

export default Routes
