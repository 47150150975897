/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useEffect, useCallback} from 'react'
import {SVG} from 'components'

export default function FilterSearch({
  filters,
  setFilters,
  isFiltered,
  isDesktop,
}) {
  const onFilterChange = (filterValues) => {
    setFilters((prev) => ({
      ...prev,
      ...filterValues,
    }))
  }

  const [searchInput, setSearchInput] = useState(filters.search)
  const handleBlur = useCallback(
    (e) => {
      if (searchInput !== filters.search) {
        onFilterChange({search: searchInput})
      }
    },
    [searchInput, filters],
  )
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && searchInput !== filters.search) {
        onFilterChange({search: searchInput})
      }
    },
    [searchInput, filters],
  )

  useEffect(() => {
    if (!filters.search) {
      setSearchInput('')
    }
  }, [filters])

  const handleClearSearch = useCallback((e) => {
    onFilterChange({search: ''})
  }, [])

  return (
    <>
      <div className="search relative flex items-center text-black-600">
        <input
          type="text"
          value={searchInput || ''}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={handleKeyPress}
          className="input filter-search"
          placeholder="search"
          onBlur={handleBlur}
        />
        <span className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {searchInput ? (
            <button
              type="button"
              className="pointer-events-auto"
              onClick={handleClearSearch}>
              <SVG name="close" className="text-gray-500 hover:text-gray-900" />
            </button>
          ) : (
            <SVG
              name="search"
              className={`text-black-500 ${isDesktop ? '' : 'text-black-100'}`}
            />
          )}
        </span>
      </div>
    </>
  )
}
