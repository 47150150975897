import React from 'react'
import {Button, SVG} from '@quotefactory/ui'
import {useSetupPathfinderMutation} from 'services/rtk-api'

/** *****************************************************************************
 * Pathfinder status and actions
 *******************************************************************************/

export default function AccountPathfinderCard({account}) {
  const [setupPathfinder, {data = {}, isLoading}] = useSetupPathfinderMutation()

  // Get setupState from the integration, or override with the response data
  const {output} = data
  const ai = account?.integrations?.find((i) => i.code === 'pathfinder')
  let setupState = ai?.connectionConfig.find(
    (i) => i.key === 'setupState',
  )?.value

  if (output?.setupState) setupState = output.setupState

  // Errors can be in two places
  const pfError = data?.error || output?.error?.message

  const handleSetupClick = async () => {
    await setupPathfinder({accountId: account.id})
  }

  const allProps = {
    onSetupClick: handleSetupClick,
    isLoading,
    setupState,
  }

  let content = <PathfinderInitialSetup {...allProps} />

  if (setupState === 'completed') {
    content = <PathfinderSetupComplete {...allProps} />
  } else if (setupState) {
    content = <PathfinderPartialSetup {...allProps} />
  }

  return (
    <div className="rounded-lg border border-gray-400 p-3 max-w-[320px]">
      <div className="flex items-center gap-1 pb-2 text-12 text-black-100 font-semibold">
        Pathfinder
      </div>

      <div className="flex flex-col space-y-4">
        {content}

        {!!pfError && (
          <div className="w-full mt-2 rounded-lg border border-red-500 bg-red-100 text-red-700 p-2 text-10 break-words">
            <SVG name="warning" className="inline mr-2" /> {pfError}
          </div>
        )}
      </div>
    </div>
  )
}

/** *****************************************************************************
 * Pathfinder is ready to set up.
 *******************************************************************************/

function PathfinderInitialSetup({onSetupClick, isLoading}) {
  return (
    <>
      <Button
        className="btn !bg-purple-500 w-full !text-14"
        onClick={onSetupClick}
        disabled={isLoading}>
        {isLoading && <SVG name="loading" className="inline mr-4" />}
        {isLoading ? 'Working...' : 'Set up Pathfinder'}
      </Button>
    </>
  )
}

/** *****************************************************************************
 * Pathfinder setup is complete
 *******************************************************************************/

function PathfinderSetupComplete() {
  return (
    <>
      <div className="bg-white flex items-center px-3 py-2 rounded-lg font-semibold text-14 w-full group">
        <span className="w-2 h-2 inline-block rounded-full ml-1.5 mr-2.5 bg-green-500" />
        <span>Active</span>
      </div>
    </>
  )
}

/** *****************************************************************************
 * Pathfinder setup is incomplete
 *******************************************************************************/

function PathfinderPartialSetup({onSetupClick, isLoading, setupState}) {
  const stateMap = {
    new: 'New',
    configPhone: 'Phone',
    createUsers: 'Users',
    addAdmins: 'Admins',
    configIntegrations: 'Integrations',
    syncStats: 'Stats',
    completed: 'Completed',
  }

  return (
    <>
      <div className="bg-white flex items-center px-3 py-2 rounded-lg font-semibold text-14 w-full group">
        <span className="w-2 h-2 inline-block rounded-full ml-1.5 mr-2.5 bg-orange-500" />
        <span>In progress ({stateMap[setupState] || setupState})</span>
      </div>
      <Button
        className="btn !bg-purple-500 w-full !text-14"
        onClick={onSetupClick}
        disabled={isLoading}>
        {isLoading && <SVG name="loading" className="inline mr-4" />}
        {isLoading ? 'Working...' : 'Continue setup'}
      </Button>
    </>
  )
}
