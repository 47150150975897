import * as React from 'react'
import {format, subMonths, startOfMonth, endOfMonth} from 'date-fns'
import {useSelector, useDispatch} from 'react-redux'
import {Button} from '@quotefactory/ui'
import {QFDateRangePicker} from 'components/ui'
import {fetchAccounts, selectAccounts} from 'reducers/account'
import {FilterSelect} from 'components/filters'

const dtFmt = (dt) => format(dt, 'yyyy-MM-dd')

const now = new Date()

export default function MonthlyBillingBrokerDetail({onRun, isRunning}) {
  const [formValues, setFormValues] = React.useState(() => {
    const startDt = startOfMonth(subMonths(now, 1))
    const endDt = endOfMonth(startDt)
    return {
      start: startDt,
      end: endDt,
      brokerId: '',
    }
  })

  const dispatch = useDispatch()
  const {loadedAt} = useSelector((state) => state.account)
  const brokers = useSelector(selectAccounts) || []

  React.useEffect(() => {
    if (!loadedAt) {
      dispatch(fetchAccounts())
    }
  }, [])

  // If possible, limit the list of options to distinct values that exist in our data
  const options = brokers.map((item) => ({
    value: item.id,
    label: item.name,
    img: {url: item.logoUrl, name: item.name},
  }))

  const onSelectBroker = (brokerId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      brokerId,
    }))
  }

  const onSelectDateRange = (start, end) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      start,
      end,
    }))
  }

  const onSubmit = () => {
    const dtStart = dtFmt(formValues.start)
    const dtEnd = dtFmt(formValues.end)
    onRun({
      reportName: 'MonthlyBillingBrokerDetail',
      runAsync: true,
      params: {dtStart, dtEnd, brokerId: formValues.brokerId},
    })
  }

  const btnDisabled =
    !formValues.brokerId || !formValues.start || !formValues.end

  return (
    <div>
      <h2>Monthly Billing Broker Detail</h2>

      <div className="pt-4">
        <div className="mt-2">
          <QFDateRangePicker
            start={formValues.start}
            end={formValues.end}
            onSelect={onSelectDateRange}
          />
          <FilterSelect
            entity="broker"
            placeholder="select a broker"
            options={options}
            selectedValue={formValues.brokerId}
            width={300}
            onSelect={onSelectBroker}
          />
        </div>
        <Button
          className="mt-4 !px-10"
          loading={isRunning}
          onClick={onSubmit}
          disabled={btnDisabled}>
          Run
        </Button>
      </div>
    </div>
  )
}
