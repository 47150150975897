import React from 'react'
import {Timestamp} from '@quotefactory/ui'
import {Link} from '@reach/router'
import {useDispatch} from 'react-redux'
import {clearProviderIntegrations} from 'reducers/providerIntegrations'
import {Avatar} from 'components'

export default function AccountListItem({account}) {
  const dispatch = useDispatch()
  if (!account) return null
  const {
    id,
    name,
    logoUrl,
    accountType,
    teamSize,
    disabled,
    customersCount,
    quoteRequestsCount,
    shipmentsCount,
    createdAt,
    recentlyBookedShipments = [],
    isTest,
  } = account

  const [latestShipment] = recentlyBookedShipments

  const resetAccountData = () => {
    dispatch(clearProviderIntegrations())
  }

  // eslint-disable-next-line no-nested-ternary
  const status = disabled ? 'disabled' : isTest ? 'test' : 'live'

  return (
    <Link
      to={`/account/${id}`}
      onClick={resetAccountData}
      className={`text-13 flex items-center gap-2 px-3 md:px-4 my-0 w-full rounded-md group hover:shadow-[0_0_0_2px] hover:shadow-blue-500 after:absolute after:inset-0 after:-z-1 after:rounded-md  hover:after:bg-white duration-100 relative z-0 py-3 ${
        disabled ? 'after:bg-gray-300/50' : 'after:bg-white'
      }`}>
      <div
        title={status}
        className={`mr-2 w-2.5 h-2.5 border rounded-full ${
          !isTest && !disabled && 'bg-green-500 border-green-600'
        } ${isTest && !disabled && 'bg-orange-500 border-orange-600'} ${
          disabled && 'bg-red-500 border-red-600'
        }`}
      />
      <div className="w-12 flex justify-center pr-2">
        {logoUrl ? (
          <img className="block w-12 h-auto " src={logoUrl} alt={name} />
        ) : (
          <Avatar url={logoUrl} title={name} size={2} />
        )}

        {/* <img class="rounded-sm w-8 h-8" src={logoUrl} alt={name} /> */}
      </div>
      <div className="flex-1">
        <div className="font-bold text-15">{name}</div>
        <div className="text-black-100 text-12 pt-1">
          <span className="inline-block px-1 py-0 mr-2 border border-gray-400 rounded-sm text-10 font-semibold uppercase tracking-wide">
            {accountType}
          </span>
          <span>
            {' '}
            created <Timestamp timestamp={createdAt} />
          </span>
        </div>
      </div>
      <div className="w-1/12 truncate text-center">{teamSize}</div>
      <div className="w-1/12 truncate text-center">{customersCount}</div>
      <div className="w-1/12 truncate text-center">{quoteRequestsCount}</div>
      <div className="w-1/12 truncate text-center">{shipmentsCount}</div>
      <div className="w-1/12 truncate text-center">
        {latestShipment?.bookedAt ? (
          <Timestamp timestamp={latestShipment.bookedAt} />
        ) : (
          '-'
        )}
      </div>
    </Link>
  )
}
