import React, {useState, useEffect} from 'react'
import {AccountUser, AccountInvitee} from 'components/account'
import api from 'services/api'

export default function AccountUsers({accountId}) {
  const [team, setTeam] = useState({})

  const fetchTeam = async () => {
    const result = await api.fetchAccountUsers(accountId)
    setTeam(result)
  }
  useEffect(() => {
    fetchTeam()
  }, [])

  if (!team?.team) return null

  return (
    <>
      <ul className="py-3 flex flex-col gap-3">
        {team?.team &&
          team?.team.map((member) => (
            <li key={member.id}>
              <AccountUser member={member} />
            </li>
          ))}

        {team?.invites &&
          team?.invites.map((invite) => (
            <li key={invite.id}>
              <AccountInvitee invite={invite} />
            </li>
          ))}
      </ul>
    </>
  )
}
