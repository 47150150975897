import React from 'react'
import {
  Button,
  Pressable,
  Modal,
  Toggle,
  Divider,
  NumberInput,
  createFormatterFn,
} from '@quotefactory/ui'
import {useDispatch} from 'react-redux'
import {fetchAccount, updateAccount} from 'reducers/account'
import OverrideGlobalNumberField from 'components/OverrideGlobalNumberField'
import api from 'services/api'

// TODO: serve this default from the API
const DEFAULT_NETWORK_MARGIN = 8

export const formatAsUSDsansDecimals = createFormatterFn({
  locale: 'en-US',
  currency: 'USD',
  decimalPlaces: 0,
})

export default function AccountSettings({account}) {
  const accountId = account.id

  const [nextBOLNumber, setNextBOLNumber] = React.useState(
    account.nextBOLNumber,
  )
  React.useEffect(
    () => setNextBOLNumber(account.nextBOLNumber ?? ''),
    [account.nextBOLNumber],
  )

  const [error, setError] = React.useState()
  const [openModal, setOpenModal] = React.useState(null)
  const closeModal = React.useCallback(() => {
    setOpenModal(null)
  }, [setOpenModal])

  const dispatch = useDispatch()

  const {isAutoDispatching, profile: brokerProfile} = account

  const handleToggleIsAutoDispatching = () => {
    dispatch(updateAccount({accountId, isAutoDispatching: !isAutoDispatching}))
  }

  const handleToggleProfile = (profileName, value) => () => {
    dispatch(
      updateAccount({
        accountId,
        [profileName]: !value,
      }),
    )
  }

  const handleNetworkMarginBlur = ({target}) => {
    const {id, value} = target
    const update = {
      accountId,
    }
    if (value) {
      update[id] = value
    } else {
      update.useGlobalNetworkMargin = true
    }
    dispatch(updateAccount(update))
  }

  const handleNextBolNumber = async (_evt, nextNumber) => {
    setError()
    if (nextNumber > 0) {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          `Are you sure you want to set the next BOL number for ${account.name} to ${nextNumber}?`,
        )
      ) {
        try {
          setNextBOLNumber(nextNumber)
          await api.setBolSequence(account.id, nextNumber)
          await dispatch(fetchAccount(account.id))
        } catch (err) {
          setError(err.message)
        }
      } else {
        setNextBOLNumber(account.nextBOLNumber ?? '')
      }
    }
  }

  const showAccountNumber =
    brokerProfile?.bolCustomizations?.showAccountNumber ?? true
  const showQuoteNumber =
    brokerProfile?.bolCustomizations?.showQuoteNumber ?? true
  const showTLCarrierName =
    brokerProfile?.bolCustomizations?.showTLCarrierName ?? false
  const showAllXNRates = brokerProfile?.showAllXNRates ?? false

  return (
    <>
      <Pressable
        className="bg-transparent rounded-lg p-3 hover:shadow-[0_0_0_2px] hover:shadow-blue-500 transition duration-300 flex flex-col items-stretch !-mt-3"
        onClick={() => {
          setOpenModal('customer-settings')
        }}>
        <div className="text-13">
          <div className="flex items-center justify-between border-b border-gray-300 pb-2 mb-3">
            <div className="text-black-100 text-12 up font-semibold flex items-center space-x-1">
              <span>Settings</span>
            </div>
          </div>
          <div>
            <div className="py-1 flex items-center gap-2">
              <div
                className={`rounded-full w-3 h-3 border ${
                  isAutoDispatching
                    ? 'bg-green-500 border-green-500'
                    : 'bg-gray-200 border-gray-500'
                }`}
              />
              <div className={!isAutoDispatching ? 'text-gray-700' : ''}>
                Auto-dispatching
              </div>
            </div>
            <div className="py-1 flex items-center gap-2">
              <div
                className={`rounded-full w-3 h-3 border ${
                  brokerProfile?.isCoverageServices
                    ? 'bg-green-500 border-green-500'
                    : 'bg-gray-200 border-gray-500'
                }`}
              />
              <div
                className={
                  !brokerProfile?.isCoverageServices ? 'text-gray-700' : ''
                }>
                Coverage services
              </div>
            </div>
            <div className="py-1 flex items-center gap-2">
              <div
                className={`rounded-full w-3 h-3 border ${
                  brokerProfile?.isCoverageServices &&
                  brokerProfile?.isShipmentAutoCover
                    ? 'bg-green-500 border-green-500'
                    : 'bg-gray-200 border-gray-500'
                }`}
              />
              <div
                className={
                  (!brokerProfile?.isCoverageServices ||
                    !brokerProfile?.isShipmentAutoCover) &&
                  'text-gray-700'
                }>
                Default auto-cover for shipments
              </div>
            </div>
            <div className="py-1 flex items-center gap-2">
              <div
                className={`rounded-full w-3 h-3 border ${
                  showAccountNumber
                    ? 'bg-green-500 border-green-500'
                    : 'bg-gray-200 border-gray-500'
                }`}
              />
              <div className={!showAccountNumber ? 'text-gray-700' : ''}>
                Show account number on BOL
              </div>
            </div>
            <div className="py-1 flex items-center gap-2">
              <div
                className={`rounded-full w-3 h-3 border ${
                  showQuoteNumber
                    ? 'bg-green-500 border-green-500'
                    : 'bg-gray-200 border-gray-500'
                }`}
              />
              <div className={!showQuoteNumber ? 'text-gray-700' : ''}>
                Show quote number on BOL
              </div>
            </div>
            <div className="py-1 flex items-center gap-2">
              <div
                className={`rounded-full w-3 h-3 border ${
                  showTLCarrierName
                    ? 'bg-green-500 border-green-500'
                    : 'bg-gray-200 border-gray-500'
                }`}
              />
              <div className={!showTLCarrierName ? 'text-gray-700' : ''}>
                Show TL carrier on BOL
              </div>
            </div>
            <div className="py-1 flex items-center gap-2">
              <div
                className={`rounded-full w-3 h-3 border ${
                  account.brokerProfile?.isExtendedNetworkConsumer
                    ? 'bg-green-500 border-green-500'
                    : 'bg-gray-200 border-gray-500'
                }`}
              />
              <div
                className={
                  !account.brokerProfile?.isExtendedNetworkConsumer
                    ? 'text-gray-700'
                    : ''
                }>
                XN consumer
              </div>
            </div>
            <div className="py-1 flex items-center gap-2">
              <div
                className={`rounded-full w-3 h-3 border ${
                  showAllXNRates
                    ? 'bg-green-500 border-green-500'
                    : 'bg-gray-200 border-gray-500'
                }`}
              />
              <div className={!showAllXNRates ? 'text-gray-700' : ''}>
                Show XN and direct rates
              </div>
            </div>
            <Divider />
            <div className="py-1 flex items-center justify-between gap-2">
              <div className="flex items-baseline gap-1 pl-5">
                <div>XN margin</div>
              </div>
              <div className="font-bold flex items-baseline gap-2">
                {brokerProfile?.networkMargin && (
                  <div className="border border-gray-900 rounded text-black-100 text-10 uppercase font-bold px-1 py-0.25">
                    Custom
                  </div>
                )}
                {brokerProfile?.networkMargin ?? DEFAULT_NETWORK_MARGIN}%
              </div>
            </div>
          </div>
        </div>
      </Pressable>

      <Modal
        isOpen={openModal === 'customer-settings'}
        onClose={closeModal}
        shouldTriggerOnCloseWithOutsideClick>
        <Modal.Content className="lg:w-[400px] overflow-auto">
          <div className="flex flex-col items-stretch p-8 pb-10">
            <div className="text-17 font-bold pb-3">Settings</div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div>Auto-dispatching</div>
                <Toggle
                  id="isAutoDispatching"
                  name="isAutoDispatching"
                  checked={isAutoDispatching}
                  onChange={handleToggleIsAutoDispatching}
                />
              </div>
              <div className="flex items-center justify-between">
                <div>Coverage services</div>
                <Toggle
                  id="isCoverageServices"
                  name="isCoverageServices"
                  checked={!!brokerProfile?.isCoverageServices}
                  onChange={handleToggleProfile(
                    'brokerProfile.isCoverageServices',
                    brokerProfile?.isCoverageServices,
                  )}
                />
              </div>
              <div className="flex items-center justify-between">
                <div>Default auto-cover</div>
                <Toggle
                  id="isShipmentAutoCover"
                  name="isShipmentAutoCover"
                  checked={!!brokerProfile?.isShipmentAutoCover}
                  disabled={!brokerProfile?.isCoverageServices}
                  onChange={handleToggleProfile(
                    'brokerProfile.isShipmentAutoCover',
                    brokerProfile?.isShipmentAutoCover,
                  )}
                />
              </div>
              <div className="flex items-center justify-between">
                <div>Show account number on bol</div>
                <Toggle
                  id="brokerProfile.bolCustomizations.showAccountNumber"
                  name="brokerProfile.bolCustomizations.showAccountNumber"
                  checked={showAccountNumber}
                  onChange={handleToggleProfile(
                    'brokerProfile.bolCustomizations.showAccountNumber',
                    showAccountNumber,
                  )}
                />
              </div>

              <div className="flex items-center justify-between">
                <div>Show quote number on bol</div>
                <Toggle
                  id="brokerProfile.bolCustomizations.showQuoteNumber"
                  name="brokerProfile.bolCustomizations.showQuoteNumber"
                  checked={showQuoteNumber}
                  onChange={handleToggleProfile(
                    'brokerProfile.bolCustomizations.showQuoteNumber',
                    showQuoteNumber,
                  )}
                />
              </div>

              <div className="flex items-center justify-between">
                <div>Show carrier on TL on bol</div>
                <Toggle
                  id="brokerProfile.bolCustomizations.showTLCarrierName"
                  name="brokerProfile.bolCustomizations.showTLCarrierName"
                  checked={showTLCarrierName}
                  onChange={handleToggleProfile(
                    'brokerProfile.bolCustomizations.showTLCarrierName',
                    showTLCarrierName,
                  )}
                />
              </div>

              <div className="flex items-center justify-between">
                <div>XN consumer</div>
                <Toggle
                  id="brokerProfile.isExtendedNetworkConsumer"
                  name="brokerProfile.isExtendedNetworkConsumer"
                  checked={account.brokerProfile?.isExtendedNetworkConsumer}
                  onChange={handleToggleProfile(
                    'brokerProfile.isExtendedNetworkConsumer',
                    brokerProfile?.isExtendedNetworkConsumer,
                  )}
                />
              </div>

              <div className="flex items-center justify-between">
                <div>Show XN and direct rates</div>
                <Toggle
                  id="brokerProfile.showAllXNRates"
                  name="brokerProfile.showAllXNRates"
                  checked={showAllXNRates}
                  onChange={handleToggleProfile(
                    'brokerProfile.showAllXNRates',
                    showAllXNRates,
                  )}
                />
              </div>

              <div className="flex items-center justify-between">
                <div>XN margin</div>
                <OverrideGlobalNumberField
                  id="brokerProfile.networkMargin"
                  units="%"
                  decimals={1}
                  min={0}
                  max={99.9}
                  onBlur={handleNetworkMarginBlur}
                  localValue={brokerProfile?.networkMargin}
                  globalValue={DEFAULT_NETWORK_MARGIN}
                  error={null}
                />
              </div>

              <div className="flex items-center justify-between">
                <div>Set next BOL #</div>
                <NumberInput
                  id="nextBolNumber"
                  name="nextBolNumber"
                  type="number"
                  largeStep="100"
                  numericValue={nextBOLNumber}
                  min={0}
                  onBlur={handleNextBolNumber}
                  aria-label="Set next BOL number"
                  decimalPlaces={0}
                  className="text-right w-28"
                />
              </div>
            </div>

            {error && (
              <div className="p-2 text-red-600 rounded-md">{error}</div>
            )}

            <div className="pt-8">
              <Button className="btn blue w-full !text-17" onClick={closeModal}>
                Done
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}
