import * as React from 'react'
import {Checkbox, ClickToCopy, SVG} from '@quotefactory/ui'

const hostURL = config.QF_API_URL

const QuickBooksOnlineForm = function ({
  accountId,
  formValues,
  setFormValues,
  submitForm,
  code,
  error,
}) {
  const activeChange = ({target}) => {
    setFormValues((prev) => ({...prev, active: target.checked}))
  }

  const onSubmit = () => {
    const updateIntegration = {
      active: formValues.active,
    }

    submitForm(updateIntegration)
  }

  return (
    <div className="p-8">
      <div className="pt-8">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Code:</span>
              <span>{code}</span>
            </div>
          </div>

          <div className="flex justify-center my-3">
            <label className="flex">
              <Checkbox
                name="active"
                checked={formValues.active}
                onChange={activeChange}
              />
              <span className="ml-2">Active</span>
            </label>
          </div>

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8">
            <span className="block w-full">
              <button
                type="button"
                onClick={onSubmit}
                className="btn blue shadowed w-full">
                Update
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default QuickBooksOnlineForm
